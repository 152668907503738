const AppConfigDefs = {
    login: {
        logoWhite: 'layout/images/logo-dark.svg',
        logoDark: 'layout/images/logo-white.svg',
        logoStyle: 'width:81px; height:60px;'
    },
    topbar: {
        logoWhite: 'layout/images/logo-dark.svg',
        logoDark: 'layout/images/logo-white.svg',
        logoStyle: 'width:81px; height:60px;',
        appName: 'Chamados'
    }
};

export default AppConfigDefs;