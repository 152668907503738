const AppConfigDefs = {
    app: {
        abreviatura: "SIC",
        nome: "Sistema de Chamados",
        empresa: "Definity Tecnologia",
        footer: "Definity Tecnologia - 2022"
    },
    login: {
        logo: {
            ligth: 'layout/definitytecnologia/images/logo-light.png',
            dark: 'layout/definitytecnologia/images/logo-dark.png',
            style: 'height: 80px;'
        },
        logoname: {
            ligth: 'layout/definitytecnologia/images/logo-nome-light.png',
            dark: 'layout/definitytecnologia/images/logo-nome-dark.png',
            style: 'width: 220px;'
        }
    },
    topbar: {
        logo: {
            ligth: 'layout/definitytecnologia/images/logo-light.png',
            dark: 'layout/definitytecnologia/images/logo-dark.png',
            style: 'height:60px;'
        },
        logoname: {
            ligth: 'layout/definitytecnologia/images/logo-nome-light.png',
            dark: 'layout/definitytecnologia/images/logo-nome-dark.png',
            style: 'height:40px;'
        }
    }
};

export default AppConfigDefs;