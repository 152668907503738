<template>
	<div class="layout-topbar">
        <div class="layout-topbar-left">
            <a href="#" class="topbar-menu-button" @click="onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

            <a href="#" class="logo">
                <!--
                <img :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'">
                -->
				<img :src="logo" :style="appConfigDefs.topbar.logo.style" />
            </a>

            <a href="#">
                <!--
                    <img :src="'layout/images/appname-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
                -->
                <img :src="logoname" :style="appConfigDefs.topbar.logoname.style" />
            </a>
        </div>
        
        <Profile 
            ref="profile" />

        <AppMenu :model="items" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :activeInlineProfile="activeInlineProfile"
            @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" 
            @menu-click="onMenuClick" @root-menuitem-click="onRootMenuItemClick" @menuitem-click="onMenuItemClick" />

        <div class="layout-topbar-right">
            <ul class="layout-topbar-right-items">
                <li id="profile" class="profile-item" :class="{'active-topmenuitem': topbarMenuActive}">
                    <a href="#" @click="onTopbarItemClick($event, 'profile')">
                        <img :src="'https://ui-avatars.com/api/?color=d5d5d5&background=28555D&length=2&rounded=true&size=128&font-size=0.35&bold=true&name=' + nomeUsuario" alt="avatar" style="width: 44px; height: 44px;" />
                    </a>

                    <ul class="fadeInDown">
                        <li role="menuitem">
                            <a href="#" @click="$refs.profile.verPerfil">
                                <i class="pi pi-fw pi-user"></i>
                                <span>Perfil</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="$refs.profile.preparaTrocaSenha">
                                <i class="pi pi-fw pi-cog"></i>
                                <span>Trocar Senha</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="$refs.profile.confirmaSair">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Sair</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--
                <li>
                    <a href="#">
                        <i class="topbar-icon pi pi-fw pi-bell"></i>
                        <span class="topbar-badge">2</span>
                        <span class="topbar-item-name">Notifications</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="topbar-icon pi pi-fw pi-comment"></i>
                        <span class="topbar-badge">5</span>
                        <span class="topbar-item-name">Messages</span>
                    </a>
                </li>
                -->
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu.vue';
import Profile from './components/Profile.vue';

export default {
	emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'menubutton-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu', 'topbar-item-click'],
	data() {
		return {
			activeTopbarItem: null,
            nomeUsuario: ""
		}
	},

	props: {
        items: Array,
		menuMode: String,
		colorScheme: String,
        topbarMenuActive: {
            type: Boolean,
            default: false
        },
        menuActive: {
            type: Boolean,
            default: false
        },
        activeInlineProfile: {
            type: Boolean,
            default: false
        }
	},

    mounted() {
        this.nomeUsuario = this.$refs.profile.getNomeUsuario();
    },

	methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },

        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },

        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },

		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},

		onTopbarItemClick(event, item) {
            this.$emit('topbar-item-click', event, item);	
			event.preventDefault();
		},

		onTopbarSubItemClick(event) {
			event.preventDefault();
		},

        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },

        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },

        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },

        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu');
        },

		isOverlay() {
			return this.menuMode === 'overlay';
		}
	},

	computed: {
        logo() {
			if (this.colorScheme === 'light') {
				return this.appConfigDefs.topbar.logo.ligth;
			} else {
				return this.appConfigDefs.topbar.logo.dark;
			}
        },

        logoname() {
			if (this.colorScheme === 'light') {
				return this.appConfigDefs.topbar.logoname.ligth;
			} else {
				return this.appConfigDefs.topbar.logoname.dark;
			}
		}
	},

	components: {
		AppMenu, Profile
	}
}
</script>