<template>
    <div :class="['layout-inline-menu', {'layout-inline-menu-active': activeInlineProfile}]" v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())">
        <Profile 
            ref="profile" />

        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
            <img :src="'https://ui-avatars.com/api/?color=d5d5d5&background=28555D&length=2&rounded=true&size=128&font-size=0.35&bold=true&name=' + nomeUsuario" alt="avatar" style="width: 44px; height: 44px;">
            <span class="layout-inline-menu-text">{{ nomeUsuario }}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item">
                    <a @click="$refs.profile.verPerfil">
                        <i class="pi pi-user pi-fw"></i>
                        <span>Perfil</span>
                    </a>
                </li>

                <li class="layout-inline-menu-action-item">
                    <a @click="$refs.profile.preparaTrocaSenha">
                        <i class="pi pi-lock"></i>
                        <span>Trocar Senha</span>
                    </a>
                </li>
                
                <li class="layout-inline-menu-action-item">
                    <a @click="$refs.profile.confirmaSair">
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Sair</span>
                    </a>
                </li>
            </ul>
        </transition>        
    </div>
</template>

<script>
import Profile from './components/Profile.vue';

export default {
    name: 'AppInlineMenu',
    emits: ['change-inlinemenu'],

    props: {
        menuMode: String,
        activeInlineProfile: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            nomeUsuario: ""
        }
    },

    mounted() {        
        if (this.$refs.profile) {
            this.nomeUsuario = this.$refs.profile.getNomeUsuario();
        }
    },

    methods: {
        onChangeActiveInlineMenu(event) {
            this.$emit('change-inlinemenu', event);
            event.preventDefault();
        },

        isHorizontal() {
            return this.menuMode === 'horizontal';
        },

        isSlim() {
            return this.menuMode === 'slim';
        },

        isOverlay() {
            return this.menuMode === 'overlay';
        },

        isStatic() {
            return this.menuMode === 'static';
        },

        isSidebar() {
            return this.menuMode === 'sidebar';
        },

        isDesktop() {
            return window.innerWidth > 991;
        },

        isMobile() {
            return window.innerWidth <= 991;
        }
    },

	components: {
        Profile
	}
}
</script>

<style scoped>
</style>