<template>
    <div class="login-body">
        <div class="login-image">
            <!--
            <img :src="'layout/images/pages/login-'+ loginColor + '.png'" alt="atlantis">
            -->
        </div>

        <Toast />

        <Loading 
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="false"/>

        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <!--
                    <img :src="'layout/images/logo-'+ color + '.png'" class="login-logo"/>
                    <img :src="'layout/images/appname-'+ color + '.png'" class="login-appname"/>
                    -->
                    <img :src="logo" alt="logo" :style="appConfigDefs.login.logo.style" />
                    <img :src="logoname" alt="logo-nome" class="login-appname" :style="appConfigDefs.login.logoname.style" />
                </div>
                
                <div class="form-container">
                    <span class="p-input-icon-left">
                        <i class="pi pi-envelope"></i>
                        <InputText type="text" placeholder="Email" v-model="formulario.login" />
                    </span>

                    <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText type="password" placeholder="Password" v-model="formulario.senha" />
                    </span>

                    <div class="flex align-items-center">
                        <Checkbox 
                            id="rememberme1" 
                            v-model="lembrar" 
                            :binary="true"
                            class="mr-2" /> 

                        <label class="flex" for="rememberme1">Lembrar minhas credenciais</label>
                    </div>

                    <a href="#" class="flex mt-5">Esqueceu a senha?</a>
                </div>

                <div class="button-container">
                    <Button 
                        label="Login" 
                        v-on:click="onLoginClick" 
                        />

                    <span>É cliente e ainda não tem conta?<a @click="teste()">Cadastre-se aqui</a></span>
                </div>
            </div>

            <!--
            <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <img src="layout/images/logo-gray.png" class="login-footer-logo"/>
                    <img src="layout/images/appname-gray.png" class="login-footer-appname"/>
                </div>
                <span>Copyright 2023</span>
            </div>
            -->
        </div>

        <Dialog 
            v-model:visible="cadastroDialog" 
            :style="{width: '650px'}" 
            header="Cadastre-se" 
            :modal="true" 
            class="p-fluid">

            <div class="field">
                <label for="nome">Informe seu nome</label>
                <InputText id="nome" v-model.trim="registro.nome" :required="true" autofocus :maxlength="50" :class="{'p-invalid': erros.nome}" />
                <small class="p-error" v-if="erros.nome">{{erros.nome}}</small>
            </div>

            <div class="field">
                <label for="login">Seu e-mail</label>
                <InputText id="login" v-model="registro.login" :required="true" :maxlength="300" :class="{'p-invalid': erros.login}" />
                <small class="p-error" v-if="erros.login">{{erros.login}}</small>
            </div>

            <div class="field">
                <label for="senha">Defina uma senha</label>
                <Password 
                    id="senha"
                    v-model="registro.senha"
                    :required="true"
                    :class="{'p-invalid': erros.senha}"
                    :toggleMask="true"
                    :maxlength="150"  />
                <small class="p-error" v-if="erros.senha">{{erros.senha}}</small>
            </div>

            <template #footer>
                <Button 
                    label="Cancelar" 
                    icon="pi pi-times" 
                    class="p-button-text" 
                    @click="cadastroDialog = false" />
                
                <Button 
                    label="Salvar" 
                    icon="pi pi-check" 
                    class="p-button-text" 
                    @click="registrar" />
            </template>
    </Dialog>

    <Dialog v-model:visible="mostraMensagemRegistro" :style="{width: '450px'}" header="Registro realizado" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>{{mensagem}}</span>
        </div>

        <template #footer>
            <Button 
                label="Ok" 
                icon="pi pi-check" 
                class="p-button-text" 
                @click="mostraMensagemRegistro = false" />
        </template>
    </Dialog>                

    <Dialog
        v-model:visible="confirmaCodigoDialog" 
        :style="{width: '450px'}" 
        :modal="true" 
        header="Confirmação de email" 
        class="p-fluid">

        <div class="field">
            <h5>{{mensagem}}</h5>
        </div>

        <div class="field">
            <label for="codigo">Código</label>
            <InputText 
                id="codigo" 
                v-model.trim="confirmaCodigoForm.codigo" :required="true" autofocus :maxlength="8" :class="{'p-invalid': erros.codigo}" />
            <small class="p-error" v-if="erros.codigo">{{erros.codigo}}</small>
        </div>

        <template #footer>
            <Button 
                label="Cancelar" 
                icon="pi pi-times" 
                class="p-button-text" 
                @click="fechaConfirmaCodigo" />
            
            <Button 
                label="Confirmar" 
                icon="pi pi-check" 
                class="p-button-text" 
                @click="confirmaCodigo" />
        </template>
    </Dialog>        
    </div>
</template>

<script>
import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    data() {
        return {
            formulario: {
                login: '',
                senha: '',
            },
            confirmaCodigoForm: {
                login: '',
                codigo: ''
            },
            lembrar: true,
            passphrase: 'descobriuHeim!?',
            registro: {
                nome: '',
                login: '',
                senha: ''
            },
            erros: [],
            cadastroDialog: false,
            confirmaCodigoDialog: false,
            mostraMensagemRegistro: false,
            isLoading: false,
            mensagem: ''
        }
    },

    cryptoJS: null,
    
    created() {
        this.cryptoJS = AES;
        this.cryptoJS.enc = ENC;
        this.carregarLembrar();
        document.title = this.appConfigDefs.app.nome + " - " + this.appConfigDefs.app.empresa;
    },

    watch: {
        lembrar(value) {
            if (value) {
                this.salvarLembrar();
            } else {
                this.limprarLembrar();
            }
        }
    },

    methods: {
        onLoginClick() {
            this.isLoading = true;

            // Atualiza o registro
            if (this.lembrar) {
                this.salvarLembrar();
            }

            this.axios
                .post("/auth/autenticar", this.formulario)
                .then((response) => {
                    this.isLoading = false;
                    
                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('usuario', JSON.stringify(response.data.usuario));

                    this.$toast.add({ severity:'success', summary: 'Olá, ' + response.data.usuario.nome, detail: 'Carregando Sistema...', life: 5000});
                    this.$isAuthenticated = true;

                    this.$router.push({ name: 'dashboard' })
                })
                .catch((error) => {
                    this.isLoading = false;

                    if (error.response && error.response.data && error.response.data.codigoErro && error.response.data.codigoErro == 201) {
                        this.confirmaCodigoDialog = true;
                        this.confirmaCodigoForm.login = this.formulario.login;
                        this.mensagem = error.response.data.mensagem;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.mensagem, life: 5000 });
                    }
                });
        },

        limprarLembrar() {
            localStorage.removeItem('login');
            localStorage.removeItem('senha');
        },

        salvarLembrar() {
            localStorage.setItem('login', this.formulario.login);
            localStorage.setItem('senha', this.esconder(this.formulario.senha));
        },

        carregarLembrar() {
            let login = localStorage.getItem('login');
            if (login) {
                this.lembrar = true;
                this.formulario.login = login;
                this.formulario.senha = this.revelar(localStorage.getItem('senha'));
            } else {
                this.lembrar = false;
            }
        },

        esconder(text) {
            return this.cryptoJS.encrypt(text, this.passphrase).toString();
        },

        revelar(ciphertext) {
            const bytes = this.cryptoJS.decrypt(ciphertext, this.passphrase);
            const originalText = bytes.toString(this.cryptoJS.enc);
            return originalText;
        },

        registrar() {
            this.isLoading = true;
            this.axios
                .post("/clienteAcesso/registrar", this.registro)
                .then((response) => {
                    this.isLoading = false;
                    this.mensagem = response.data.mensagem;
                    this.cadastroDialog = false;
                    this.mostraMensagemRegistro = true;
					//this.$toast.add({ severity:'success', summary: 'Sucesso', detail: , life: 5000});
                })
                .catch((error) => {
                    this.isLoading = true;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.mensagem, life: 5000 });
                });
        },

        fechaConfirmaCodigo() {
            this.confirmaCodigoDialog = false;
            this.confirmaCodigo.codigo = '';
        },

        confirmaCodigo() {
            this.axios
                .post("/auth/confirmaCodigo", this.confirmaCodigoForm)
                .then((response) => {
                    this.$toast.add({ severity:'success', summary: 'Sucesso', detail: response.data.mensagem, life: 5000});
                    this.confirmaCodigoDialog = false;
                    this.confirmaCodigo.codigo = '';
                })
                .catch((error) => {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.mensagem, life: 5000 });
                });
        },

        teste() {
            console.log('teste');
            this.cadastroDialog = true;
        }
    },

    computed: {
        logo() {
			if (this.$appState.colorScheme === 'light') {
				return this.appConfigDefs.login.logo.ligth;
			} else {
				return this.appConfigDefs.login.logo.dark;
			}
        },

        logoname() {
			if (this.$appState.colorScheme === 'light') {
				return this.appConfigDefs.login.logoname.ligth;
			} else {
				return this.appConfigDefs.login.logoname.dark;
			}
		}
    },

    components: {
        Loading
    }
}
</script>