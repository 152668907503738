const AppConfigDefs = {
    app: {
        abreviatura: "SIC",
        nome: "Sistema de Inteligência Comercial",
        empresa: "VORTICE BI",
        footer: "VORTICE BI - 2022"        
    },
    login: {
        logo: {
            ligth: 'layout/vorticebi/images/logobi-light.png',
            dark: 'layout/vorticebi/images/logobi-dark.png',
            style: 'height: 50px;'
        },
        logoname: {
            ligth: 'layout/vorticebi/images/logobi-nome-light.png',
            dark: 'layout/vorticebi/images/logobi-nome-dark.png',
            style: 'width: 260px;'
        }
    },
    topbar: {
        logo: {
            ligth: 'layout/vorticebi/images/logobi-light.png',
            dark: 'layout/vorticebi/images/logobi-dark.png',
            style: 'height:30px; margin-left: 6px;'
        },
        logoname: {
            ligth: 'layout/vorticebi/images/logobi-nome-light.png',
            dark: 'layout/vorticebi/images/logobi-nome-dark.png',
            style: 'height: 18px;'
        }
    }
};

export default AppConfigDefs;