import { createRouter, createWebHashHistory } from 'vue-router';


const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./components/Dashboard.vue')
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('./components/Usuarios.vue')
    },
    {
        path: '/grupos',
        name: 'grupos',
        component: () => import('./components/Grupos.vue')
    },
    {
        path: '/clientes',
        name: 'clientes',
        component: () => import('./components/Clientes.vue')
    },
    {
        path: '/propClientes',
        name: 'propClientes',
        component: () => import('./components/PropClientes.vue')
    },
    {
        path: '/contratos',
        name: 'contratos',
        component: () => import('./components/Contratos.vue')
    },
    {
        path: '/softwares',
        name: 'softwares',
        component: () => import('./components/Softwares.vue')
    },
    {
        path: '/propSoftwares',
        name: 'propSoftwares',
        component: () => import('./components/PropSoftwares.vue')
    },
    {
        path: '/servicos',
        name: 'servicos',
        component: () => import('./components/Servicos.vue')
    },
    {
        path: '/propServicos',
        name: 'propServicos',
        component: () => import('./components/PropServicos.vue')
    },
    {
        path: '/chamados',
        name: 'chamados',
        component: () => import('./components/Chamados/Chamados.vue')
    },
    {
        path: '/chamados/filas',
        name: 'chamados_filas',
        component: () => import('./components/Chamados/Filas.vue')
    },
    {
        path: '/chamados/requisicoes',
        name: 'chamados_requisicoes',
        component: () => import('./components/Chamados/Requisicoes.vue')
    },
    {
        path: '/chamados/acoes',
        name: 'chamados_acoes',
        component: () => import('./components/Chamados/Acoes.vue')
    },
    {
        path: '/InteligVarejo/funcoes',
        name: 'InteligVarejo_funcoes',
        component: () => import('./components/InteligVarejo/Funcoes.vue')
    },
    {
        path: '/InteligVarejo/empresas',
        name: 'InteligVarejo_empresas',
        component: () => import('./components/InteligVarejo/Empresas.vue')
    },
    {
        path: '/InteligVarejo/colaboradores',
        name: 'InteligVarejo_colaboradores',
        component: () => import('./components/InteligVarejo/Colaboradores.vue')
    },
    {
        path: '/InteligVarejo/relatorios',
        name: 'InteligVarejo_relatorios',
        component: () => import('./components/InteligVarejo/Relatorios.vue')
    },
    {
        path: '/InteligVarejo/relatorioperdas',
        name: 'InteligVarejo_relatorioperdas',
        component: () => import('./components/InteligVarejo/RelatorioPerdas.vue')
    },
    {
        path: '/crm/analiseReprov',
        name: 'analiseReprov',
        component: () => import('./components/CRM/AnaliseReprovados.vue')
    },
    {
        path: '/crm/biefficax',
        name: 'biefficax',
        component: () => import('./components/CRM/BIEfficax.vue')
    },
    {
        path: '/crm/acoes',
        name: 'crmacoes',
        component: () => import('./components/CRM/CRMAcoes.vue')
    },
    {
        path: '/crm/interacoes',
        name: 'crminteracoes',
        component: () => import('./components/CRM/CRMInteracoes.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },    
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;