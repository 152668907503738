<template>
    <div>
        <Loading 
            v-model:active="exibeProgresso"
            :can-cancel="false"
            :is-full-page="true"/>

        <Toast/>

        <Dialog 
            v-model:visible="confirmaSaidaDialogo" 
            :style="{width: '450px'}" 
            header="Confirme" 
            :modal="true">
            
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Tem certeza que deseja sair do sistema? </span>
            </div>

            <template #footer>
                <Button 
                    label="Não" 
                    icon="pi pi-times" 
                    class="p-button-text" 
                    @click="confirmaSaidaDialogo = false"/>

                <Button 
                    label="Sim" 
                    icon="pi pi-check" 
                    class="p-button-text" 
                    @click="sair" />
            </template>
        </Dialog>

        <Dialog 
            header="Perfil" 
            v-model:visible="perfilDialogo" 
            :style="{width: '450px'}" 
            :modal="true">

            <div class="p-fluid">
                <div class="field">
                    <label for="nome">Nome</label>
                    <InputText 
                        id="nome" 
                        v-model="perfil.nome" 
                        disabled="true" />
                </div>

                <div class="field">
                    <label for="login">Login</label>
                    <InputText 
                        id="login" 
                        v-model="perfil.login" 
                        disabled="true" />
                </div>
            </div>

            <template #footer>
                <Button 
                    label="Fechar" 
                    icon="pi pi-check" 
                    class="p-button-text" 
                    @click="perfilDialogo = false" />
            </template>
        </Dialog>

        <Dialog 
            header="Troque sua senha" 
            v-model:visible="trocaSenhaDialogo" 
            :style="{width: '450px'}" 
            :modal="true">

            <div class="p-fluid">
                <div class="field">
                    <label for="atual">Senha Atual</label>
                    <Password 
                        id="atual" 
                        v-model="formTrocaSenha.senhaAtual" 
                        placeholder="Senha Atual" 
                        :toggleMask="true"
                        :required="true"
                        class="w-full mb-3" 
                        inputClass="w-full" 
                        inputStyle="padding:1rem" />
                    <small class="p-error" v-if="erros.senhaAtual">{{erros.senhaAtual}}</small>
                </div>

                <div class="field">
                    <label for="nova">Nova Senha</label>
                    <Password 
                        id="nova" 
                        v-model="formTrocaSenha.novaSenha" 
                        placeholder="Digite a nova senha" 
                        :toggleMask="true"
                        :required="true"
                        class="w-full mb-3" 
                        inputClass="w-full" 
                        inputStyle="padding:1rem" />
                    <small class="p-error" v-if="erros.novaSenha">{{erros.novaSenha}}</small>
                </div>

                <div class="field">
                    <label for="confirmaNova">Confirme a nova Senha</label>
                    <Password 
                        id="confirmaNova" 
                        v-model="formTrocaSenha.confirmaNova" 
                        placeholder="Digite a nova senha novamente" 
                        :toggleMask="true"
                        :required="true"
                        class="w-full mb-3" 
                        inputClass="w-full" 
                        inputStyle="padding:1rem" />
                    <small class="p-error" v-if="formTrocaSenha.confirmaNova && formTrocaSenha.confirmaNova.length > 0 && !senhasConferem">A nova senha informada não está idêntica a confirmação</small>
                </div>
            </div>

            <template #footer>
                <Button 
                    label="Trocar" 
                    icon="pi pi-check" 
                    class="p-button-text" 
                    :disabled="!senhasConferem"
                    @click="trocaSenha" />

                <Button 
                    label="Canclear" 
                    icon="pi pi-times" 
                    class="p-button-text" 
                    @click="trocaSenhaDialogo = false" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
        name: 'Profile',

        components: {
	    	Loading
	    },
        
        data() {
            return {
                controle: {
                    carregando: 0
                },
                confirmaSaidaDialogo: false,
                perfilDialogo:  false,
                trocaSenhaDialogo: false,
                perfil: {},
                formTrocaSenha: {},
                erros: {}
            }
        },

        methods: {
            verPerfil() {
                // Dados de quem está logado
                this.axios
                    .get('/usuario/sobreMin')
                    .then((response) => {
                        this.perfil = response.data.registro;
                        this.perfilDialogo = true;
                    })
                    .catch((error) => {
                        if (error.response.data.mensagem) {
                            this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.mensagem, life: 5000 });
                        }
                    });
            },
            
            confirmaSair() {
                this.confirmaSaidaDialogo = true;
            },

            sair() {
                localStorage.removeItem('jwt');
                this.$isAuthenticated = true;
                this.$router.push({ name: 'login' })
            },

            preparaTrocaSenha() {
                this.trocaSenhaDialogo = true;
                this.formTrocaSenha = {};			
            },

            trocaSenha() {
                this.erros = {};

                this.controle.carregando++;
                this.axios
                    .post('/auth/trocaSenha', this.formTrocaSenha)
                    .then((response) => {
                        this.controle.carregando--;
                        this.$toast.add({ severity:'success', summary: 'Sucesso', detail: response.data.mensagem, life: 5000 });
                        this.trocaSenhaDialogo = false;
                    })
                    .catch((error) => this.trataErroRetorno(error));
            },

            trataErroRetorno(error) {
                this.controle.carregando--;

                if (error.response && error.response.data && error.response.data.erros) {
                    this.erros = error.response.data.erros;
                }

                if (error.response && error.response.data && error.response.data.mensagem) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.mensagem, life: 5000 });
                } else {
                    console.log(error);
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao processar sua requisição. Consulte o suporte para mais informações!', life: 5000 });
                }
            },

            getNomeUsuario() {
                let strUsuario = localStorage.getItem('usuario');

                if (strUsuario) {
                    const usuario = JSON.parse(strUsuario);
                    return usuario.nome;
                }

                return "?";                
            }
        },

        computed: {
            exibeProgresso() {
                return (this.controle.carregando > 0);
            },
            
            senhasConferem() {
                if ((this.formTrocaSenha.novaSenha 			&& this.formTrocaSenha.novaSenha.trim().length > 0) &&
                    (this.formTrocaSenha.confirmaNova 		&& this.formTrocaSenha.confirmaNova.trim().length > 0)) {
                        return (this.formTrocaSenha.novaSenha === this.formTrocaSenha.confirmaNova);
                } else {
                    return false;
                }
    		},

            nomeUsuario() {
                let strUsuario = localStorage.getItem('usuario');

                if (strUsuario) {
                    const usuario = JSON.parse(strUsuario);
                    return usuario.nome;
                }

                return "?";
            }
	    }            
	}
</script>